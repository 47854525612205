import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import SemanticInput from '../../components/SemanticInput';
import moment from 'moment';
import ButtonsFilter from '../../components/ButtonsFilter';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, FormInput, Grid, Segment } from 'semantic-ui-react';
import DropdownReceptores from '../../components/Formik/DropdownReceptores';

const reducerQuery = (prevState, { name, value, type, query }) => {

  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
  
};

const Filters = ({ isBusy, query: base, setQuery }) => {
  const today = new Date();
  const [startDate, setStartDate] = useState( new Date(today.getFullYear(), today.getMonth(), 1) );
  const [finalDate, setFinalDate] = useState( new Date(today.getFullYear(), today.getMonth() + 1, 0) );
  const [receptorNombre, setReceptorNombre] = useState('');

  const queryInit = {
    folio: '',
    idTipoComprobante: '',
    idStatus: '',
    Serie: '',
    ReceptorNombre:'',
    fechaInicial: startDate,
    fechaFinal: finalDate,
  };

  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    const sendQuery = () => {
      handleSearchClick();
    };

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => dispatchQuery({ name, value });

  const handleSearchClick = (event, data) => {
    const queryMap = {
      ...(query.folio && { Folio: query.folio }),
      ...(query.idTipoComprobante && {
        idTipoComprobante: query.idTipoComprobante,
      }),
      ...(query.idStatus && {
        idStatus: query.idStatus,
      }),
      ...(query.Serie && {
        Serie: query.Serie,
      }),
      ...( query.ReceptorNombre && {
        ReceptorNombre: query.ReceptorNombre
      } ),
      ...(startDate &&
        finalDate && {
          FechaDocumento: {
            $gte: moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
            $lte: moment(finalDate).format('YYYY-MM-DD 23:59:59.000'),
          },
        }),
    };

    console.log( 'querymap: ', queryMap );

    // Send query to Partners
    setQuery && setQuery(queryMap);
  };

  const handleGenerateReport = () => {
    // Ejemplo de datos JSON
    const jsonData = [
      { Columna1: 'Valor1', Columna2: 'Valor2', Columna3: 'Valor3' },
      { Columna1: 'Valor4', Columna2: 'Valor5', Columna3: 'Valor6' },
    ];

    // Convertir el JSON a una cadena CSV
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      Object.keys(jsonData[0]).join(',') +
      '\n' +
      jsonData.map((obj) => Object.values(obj).join(',')).join('\n');

    // Crear un elemento <a> para descargar el archivo
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'reporte.csv');
    document.body.appendChild(link); // Agregar el enlace al DOM
    link.click(); // Simular el clic en el enlace para descargar el archivo
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,
      },
    });
  };

  return (
    <>
      <Segment>
        <Form>
          <FormGroup widths={2}>
            <SemanticInput
              name="folio"
              label="Folio"
              value={query.folio}
              onChange={handleChangeInput}
            />
            <SemanticInput
              name="idTipoComprobante"
              label="Tipo de Comprobante"
              value={query.idTipoComprobante}
              onChange={handleChangeInput}
            />
            <DropdownReceptores
                fluid
                selection
                label="Nombre Receptor*"
                value={receptorNombre}
                onChange={async (e, { value }) => {
                  query.ReceptorNombre = value.Nombre;
                  setReceptorNombre(value);
                }}
                placeholder="Nombre Receptor"
                featherService="receptores"
                columName="Nombre"
                columValue="Nombre"
                mapValues={(value) => {
                  return {
                    key: Math.random(),
                    text: `${value['Nombre']}`,
                    value: value,
                  };
                }}
            />
            <SemanticInput
              name="idStatus"
              label="Status"
              value={query.idStatus}
              onChange={handleChangeInput}
            />
            <SemanticInput
              name="Serie"
              label="Serie"
              value={query.Serie}
              onChange={handleChangeInput}
            />
            <div className="field equal width">
              <label>
                <b>Fecha Inicial</b>
              </label>
              <DatePicker
                selected={startDate}
                onChange={(data) => {
                  setStartDate(data);
                }}
                timeInputLabel="Hora:"
                dateFormat="dd/MM/yyyy"
                name="fechaInicial"
                value={startDate}
              />
            </div>
            <div className="field equal width">
              <label>
                <b>Fecha Final</b>
              </label>
              <DatePicker
                selected={finalDate}
                onChange={(data) => {
                  setFinalDate(data);
                }}
                timeInputLabel="Hora:"
                dateFormat="dd/MM/yyyy"
                name="fechaInicial"
                value={finalDate}
              />
            </div>
            <div style={{ width: '160px' }} />
            <ButtonsFilter isBusy={isBusy} onSearchClick={handleSearchClick} />
          </FormGroup>
        </Form>
      </Segment>
    </>
  );
};

Filters.propTypes = {
  isBusy: PropTypes.bool,
  setQuery: PropTypes.func,
  defaultUser: PropTypes.object,
  isValid: PropTypes.bool,
};

export default Filters;
