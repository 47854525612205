import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';
import dataTest from './dataTest';
import ModalUpload from './ModalUpload';
import JSZip from 'jszip';
import moment from 'moment';

const BillOfLadingMx = (props) => {
  const service = feathers.service('documentos');
  const servicePDF = feathers.service('export-pdf-cartaporte');
  const auth = useAuth();

  // States
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [isSupport, setIsSupport] = useState(false);
  const [client, setClient] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const finalDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [query, setQuery] = useState({
    FechaDocumento: {
      $gte: moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      $lte: moment(finalDate).format('YYYY-MM-DD 23:59:59.000'),
    },
  });
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingXML, setLoadingXML] = useState(false);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  useEffect(() => {

    const { isSupport } = (auth && auth.user) || {};
    const client = (auth && auth.user && auth.user.client) || null;
    const isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setClient(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  // Functions
  const getData = async () => {
    if (query || auth.user) {
      let data = [],
        result = [];

      // console.log( 'esta es la query: ', query );

      try {
        setIsBusy(true);
        setSelection([]);
        do {
          if (auth.user) {
            result = await service.find({
              query: {
                Organizacion: auth.user.client._id,
                ...query,
                $sort: { id: -1 },
                $skip: data.length,
              },
            });
          }

          data = data.concat(result.data);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Errorrr', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get BillOfLadingMx',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
        // console.log( '********************* informacion recolectada del data ************************** ', data );
      }
    }
  };

  const setTestData = () => {
    setData(dataTest(true));
    setOpenModalUpload(false);

    setTimeout(() => {
      setData(dataTest(false));
    }, 5000);
  };

  const openDrawer = (documentId) => {
    setCurrentDocumentId(documentId);
    setOpenPanel(true);
  };

  // Handles
  const handleCreateClick = () => {
    openDrawer(null);
  };

  const handleEditClick = (documentId) => {
    openDrawer(documentId);
  };

  const saveFilePDF = async (data) => {
    let html = await servicePDF.get(data.id);
    const blob = new Blob([html], { type: 'application/pdf' });
    return {
      blob,
      filename: `${data.EmisorRFC}_${data.ReceptorRFC}_${data.Serie}${data.Folio}_${data.timbre_uuid}.pdf`,
    };
  };

  // Función para crear un archivo ZIP y agregar los blobs de los PDF descargados
  const downloadPDFs = async () => {
    setLoadingPDF(true);
    // Crear un objeto para almacenar las entradas del archivo ZIP
    const zipEntries = [];

    // Iterar sobre la lista de datos y agregar cada PDF al archivo ZIP
    for (const data of selection) {
      const { blob, filename } = await saveFilePDF(data);

      // Agregar el blob y el nombre del archivo a las entradas del ZIP
      zipEntries.push({ filename, blob });
    }

    // Crear una función para agregar los archivos al archivo ZIP
    const createZip = () => {
      const zip = new JSZip();

      // Agregar cada archivo al archivo ZIP
      zipEntries.forEach((entry) => {
        zip.file(entry.filename, entry.blob);
      });

      // Generar el archivo ZIP
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        // Crear un enlace de descarga para el archivo ZIP
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'CFDis_PDF.zip'; // Nombre del archivo ZIP
        document.body.appendChild(link);

        // Iniciar la descarga del archivo ZIP
        link.click();

        // Limpiar y eliminar el enlace
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
    };

    // Llamar a la función para crear el archivo ZIP
    createZip();
    setLoadingPDF(false);
  };

  const saveFileXML = async (data) => {
    let parser = new DOMParser();
    let xml = parser.parseFromString(data.cfd_xml, 'application/xml');
    const serializer = new XMLSerializer();
    const xmlStr = serializer.serializeToString(xml);
    return {
      xmlStr,
      filename: `${data.EmisorRFC}_${data.ReceptorRFC}_${data.Serie}${data.Folio}_${data.timbre_uuid}.xml`,
    };
  };

  const downloadXMLs = async () => {
    setLoadingXML(true);
    const xmlEntries = [];

    // Iterar sobre la lista de datos y agregar cada XML al arreglo de XMLs
    for (const data of selection) {
      const { xmlStr, filename } = await saveFileXML(data);
      xmlEntries.push({ filename, xmlStr });
    }

    // Crear una función para agregar los archivos XML al archivo ZIP
    const createZip = () => {
      const zip = new JSZip();

      // Agregar cada archivo XML al archivo ZIP
      xmlEntries.forEach((entry) => {
        zip.file(entry.filename, entry.xmlStr);
      });

      // Generar el archivo ZIP
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        // Crear un enlace de descarga para el archivo ZIP
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'CFDis_XML.zip'; // Nombre del archivo ZIP
        document.body.appendChild(link);

        // Iniciar la descarga del archivo ZIP
        link.click();

        // Limpiar y eliminar el enlace
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
    };

    // Llamar a la función para crear el archivo ZIP
    createZip();
    setLoadingXML(false);
  };

  const handleDeleteClick = async () => {
    let success = false;

    try {
      const total = selection.length;
      let msg = `Do you want to delete ${total} ${pluralize(total, 'user')}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await service.remove(selection);

        success = true;
      }
    } catch (error) {
      console.debug(error);
      await swal('Error', "Don't Delete selected BillOfLadingMx", 'error');
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');

        setSelection([]);
        getData();
      }
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentDocumentId(null);
    setOpenPanel(false);

    isSuccess && getData();
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
            setOpenModalUpload={setOpenModalUpload}
          />
        }
      >
        <HeaderBG />

        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },

              {
                key: 'BillOfLadingMx',
                content: 'Billing MX',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            isSupport={isSupport}
            client={client}
          />

          <TableNavigation
            hasSelection={selection}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
            downloadPDFs={downloadPDFs}
            downloadXMLs={downloadXMLs}
            loadingPDF={loadingPDF}
            loadingXML={loadingXML}
          />
          <Table
            data={data}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            getData={getData}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentDocumentId={currentDocumentId}
          data={data}
          setQuery={setQuery}
        ></Drawer>
      ) : null}

      <ModalUpload
        open={openModalUpload}
        setOpen={setOpenModalUpload}
        importHandled={setTestData}
      ></ModalUpload>
    </>
  );
};

export default BillOfLadingMx;
