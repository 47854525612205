import React, { useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup } from 'semantic-ui-react';
import TableContainer from "../../../components/TableContainer";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import SemanticInput from "../../../components/SemanticInput";


const ModalComplement = ({ dataComplements, handleModalInfo ,openModal, setOpenModal, numFolios}) =>{

    const [columnDefs] = useState([
        {
          headerName: 'Folio',
          field: 'Folio',
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Serie',
          field: 'Serie',
        },
        {
          headerName: 'FechaDocumento',
          field: 'FechaDocumento',
        },
        {
          headerName: 'Moneda',
          field: 'idMoneda',
        },
        {
          headerName: 'Tipo de Cambio',
          field: 'TipoDeCambio',
        },
        {
          headerName: 'Total Pesos',
          field: 'Total',
        },
        {
          headerName: 'Saldo',
          field: 'SaldoActual',
        },
        {
          headerName: 'Monto Pago',
          field: 'MontoPago',
          editable: true,
        },
    
        {
          headerName: 'timbre_uuid',
          field: 'timbre_uuid',
        },
    ]);
    const [defaultColDef] = useState({ flex: 1 });
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState('');

    useEffect( ()=>{
        handleData();
    },[numFolios] );

    //Filtrar los complementos que ya fueron marcados
    const handleData = () =>{
        let complements = dataComplements.filter( (com)=>{
            return !numFolios.includes( com.Folio )
        })

        setData( dataComplements );
    }

    //Ejecutar cuando se selecciona una fila
    const handledSelectionChanged = ({ api }) => {
        let rows;
        let r = 0;

        try {

          if (api) {
            rows = api.getSelectedRows();
            setSelectedRows( rows );
          }

          console.log( rows );

        } catch (error) {
          console.error('error :>> ', error);
          rows = [];
        }

    };

    const onQuickFilterChanged = (e) => {
        setQuickFilterText(e.target.value);
    };

    return(
      <Modal
        onClose={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
        open={openModal}
        size={'fullscreen'}
      >
        <Modal.Header> Agregar complementos </Modal.Header>
        <Modal.Content>

            <Form>
                
                <FormGroup>
                    <SemanticInput
                    name="filtro"
                    label="Buscar en la tabla"
                    onChange={onQuickFilterChanged}
                    value={quickFilterText}
                    />
                </FormGroup>
            
                <TableContainer className="ag-theme-material" rowFilters={1}>
                    <AgGridReact
                    rowData={data}
                    modules={AllModules}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    quickFilterText={quickFilterText}
                    pagination={true}
                    paginationPageSize={100}
                    suppressRowClickSelection={true}
                    onSelectionChanged={handledSelectionChanged}
                    />
                </TableContainer>
            </Form>
          
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancelar"
            color="red"
            labelPosition="right"
            icon="cancel"
            onClick={() => setOpenModal(false)}
          />
          <Button
            content="Cerrar y guardar"
            labelPosition="right"
            icon="checkmark"
            onClick={ ()=>{ 
                handleModalInfo( selectedRows );
                setOpenModal(false)
             } }
            positive
            type='button'
          />
        </Modal.Actions>
      </Modal>
    )
}

export default ModalComplement;

