import React, { useState, useEffect } from 'react';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { FormGroup, Form, Button } from 'semantic-ui-react';
import SemanticInput from '../../components/SemanticInput';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import feathers, { useAuth } from '../../services/feathers';
import moment from 'moment';
import ModalComplement from './Drawer/ModalComplement';

const TableComplement = ({ formik, setTotal, setTipoCambio, setSelection }) => {
  const [columnDefs] = useState([
    {
      headerName: 'Folio',
      field: 'Folio',
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
    },
    {
      headerName: 'FechaDocumento',
      field: 'FechaDocumento',
    },
    {
      headerName: 'Moneda',
      field: 'idMoneda',
    },
    {
      headerName: 'Tipo de Cambio',
      field: 'TipoDeCambio',
    },
    {
      headerName: 'Total Pesos',
      field: 'Total',
    },
    {
      headerName: 'Saldo',
      field: 'SaldoActual',
    },
    {
      headerName: 'Monto Pago',
      field: 'MontoPago',
      editable: true,
    },

    {
      headerName: 'timbre_uuid',
      field: 'timbre_uuid',
    },
  ]);

  //Estados
  const [defaultColDef] = useState({ flex: 1 });
  const [quickFilterText, setQuickFilterText] = useState('');
  const [data, setData] = useState([]);
  const [fols, setFols] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dataComplements, setDataComplements] = useState([]);
  const [numFolios, setNumFolios] = useState([]);

  //Vables
  let today = new Date();
  let FoliosPayed = [];
  const auth = useAuth();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  //Servicios
  const serviceDocumentosPago = feathers.service('documentos-pagos');
  const serviceComplement = feathers.service('complemento-pago');
  const servicePOS = feathers.service('pos');

  const onQuickFilterChanged = (e) => {
    setQuickFilterText(e.target.value);
  };

  //CARGA DE LOS DATOS EN LA FILA DE LA TABLA
  const onGridReady = async (params) => {
    const { values } = formik;
    let foliosNumber = [];
    try {
      const response = await serviceDocumentosPago.find({
        query: {
          id: values.id,
        },
      });

      let getInfo = await getData(response.data[0].idUser);
      let payedComplement = await serviceComplement.find({
        query: { idSucursal: getInfo.idSucursal, id: response.data[0].id },
      });

      for (let com of payedComplement[0]) {
        com.SaldoActual = com.ImpSaldoAnt;
        com.MontoPago = com.ImpPagado.toFixed(2);
        com.TipoDeCambio = com.TipoDeCambio.toFixed(4);
        foliosNumber.push(com.Folio);
      }
      // console.log( 'complementos relacionados: ',payedComplement[0] );

      if (payedComplement[0].length > 0) {
        for (let com of payedComplement[0]) FoliosPayed.push(com.Folio);
      }

      //Obtenemos el resto de complementos de pago que no se han utilizado
      let complement = await serviceComplement.get(getInfo);
      complement[0].sort((a, b) => {
        return new Date(a.FechaDocumento) - new Date(b.FechaDocumento);
      });
      // console.log( 'complementosTotales: ', complement[0] );

      for (let item of complement[0]) {
        item.MontoPago = item.SaldoActual;
        item.TipoDeCambio = item.TipoDeCambio.toFixed(4);
      }
      let newComplement = complement[0].filter(
        (comple) => !FoliosPayed.includes(comple.Folio)
      );

      // for (let com of payedComplement[0]) newComplement.unshift(com);
      for (let num of newComplement) num.MontoPago = num.MontoPago.toFixed(2);

      setDataComplements(complement[0]);
      setNumFolios(foliosNumber);

      setFols(FoliosPayed);
      setData(payedComplement[0]);
    } catch (error) {
      console.error(error);
    }
  };

  //OBTENER LAS FILS SELECCIONADAS
  const handledSelectionChanged = ({ api }) => {
    let rows;
    let r = 0;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Clients',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
    } finally {
      if (rows.length > 0) {
        r = 0;
        setSelection(rows);
        setTipoCambio(rows[0].TipoDeCambio);
        for (let row of rows) {
          if (row.MontoPago) {
            r += parseFloat(row.MontoPago);
          }
        }
      } else {
        r = 0;
        setSelection(rows);
      }
      setTotal(r.toFixed(2));
    }
  };

  //OBTENER LOS PARAMETROS PARA LA CONSULTA DE LA TABLA
  const getData = async (id) => {
    try {
      let resultPOS = await servicePOS.find({
        query: { Nombre: auth.user.client.name },
      });
      let result = {
        idSucursal: resultPOS.data[0].idSucursal,
        idSocioNegocio: +id,
        FechaMax: moment(lastDayOfMonth).format('YYYY-MM-DD HH:mm:ss.SSS'),
      };
      return result;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  //SELECCIONAR LAS FILAS AL INICIAR LA TABLA
  const onFirstDataRendered = (params) => {
    params.api.forEachNode((node) => {
      if (fols.includes(node.data.Folio)) {
        node.setSelected(true);
      }
    });
  };

  //ACTUALIZAR TOTAL DE REGISTROS SELECCIONADOS
  const onCellValueChanged = (params) => {
    let result = 0;
    let valuesSelected = [];

    params.api.forEachNode((node) => {
      if (node.selected && node.data.MontoPago) {
        node.data.MontoPago = parseFloat(node.data.MontoPago).toFixed(2);
        result += parseFloat(node.data.MontoPago);
        valuesSelected.push(node.data);
      }
    });

    if (valuesSelected.length > 0) {
      setSelection(valuesSelected);
      setTotal(result.toFixed(2));
    }
  };

  const handleModalInfo = (rows) => {
    let folios = [];
    for (let row of rows) folios.push(row.Folio);
    setNumFolios([...numFolios, ...folios]);
    setFols([...fols, ...folios]);
    setData([...data, ...rows]);
  };

  const onModelUpdatedHandler = ({ api }) => {
    api.forEachNode((node) => {
      if (fols.includes(node.data.Folio)) {
        node.setSelected(true);
      }
    });
  };

  return (
    <Form>
      <FormGroup widths={4}>
        <SemanticInput
          name="filtro"
          label="Buscar en la tabla"
          onChange={onQuickFilterChanged}
          value={quickFilterText}
        />
      </FormGroup>

      <Button
        primary
        compact
        type="button"
        style={{ marginBottom: '20px' }}
        onClick={() => setOpenModal(true)}
      >
        Agregar complemento
      </Button>

      <TableContainer className="ag-theme-material" rowFilters={1}>
        <AgGridReact
          rowData={data}
          modules={AllModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // domLayout="autoHeight"
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={100}
          suppressRowClickSelection={true}
          quickFilterText={quickFilterText}
          onGridReady={onGridReady}
          onSelectionChanged={handledSelectionChanged}
          //getRowNodeId={(data) => data.Folio}
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellValueChanged}
          onModelUpdated={onModelUpdatedHandler}
        />
      </TableContainer>

      <ModalComplement
        openModal={openModal}
        setOpenModal={setOpenModal}
        dataComplements={dataComplements}
        numFolios={numFolios}
        handleModalInfo={handleModalInfo}
      />
    </Form>
  );
};

export default TableComplement;
